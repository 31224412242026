import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241'),
	() => import('./nodes/242'),
	() => import('./nodes/243'),
	() => import('./nodes/244'),
	() => import('./nodes/245'),
	() => import('./nodes/246'),
	() => import('./nodes/247'),
	() => import('./nodes/248'),
	() => import('./nodes/249'),
	() => import('./nodes/250'),
	() => import('./nodes/251'),
	() => import('./nodes/252'),
	() => import('./nodes/253'),
	() => import('./nodes/254'),
	() => import('./nodes/255'),
	() => import('./nodes/256'),
	() => import('./nodes/257'),
	() => import('./nodes/258'),
	() => import('./nodes/259'),
	() => import('./nodes/260'),
	() => import('./nodes/261'),
	() => import('./nodes/262'),
	() => import('./nodes/263'),
	() => import('./nodes/264'),
	() => import('./nodes/265'),
	() => import('./nodes/266'),
	() => import('./nodes/267'),
	() => import('./nodes/268'),
	() => import('./nodes/269'),
	() => import('./nodes/270'),
	() => import('./nodes/271'),
	() => import('./nodes/272'),
	() => import('./nodes/273'),
	() => import('./nodes/274'),
	() => import('./nodes/275'),
	() => import('./nodes/276'),
	() => import('./nodes/277'),
	() => import('./nodes/278'),
	() => import('./nodes/279'),
	() => import('./nodes/280'),
	() => import('./nodes/281'),
	() => import('./nodes/282'),
	() => import('./nodes/283'),
	() => import('./nodes/284'),
	() => import('./nodes/285'),
	() => import('./nodes/286'),
	() => import('./nodes/287'),
	() => import('./nodes/288'),
	() => import('./nodes/289'),
	() => import('./nodes/290'),
	() => import('./nodes/291'),
	() => import('./nodes/292'),
	() => import('./nodes/293'),
	() => import('./nodes/294'),
	() => import('./nodes/295'),
	() => import('./nodes/296'),
	() => import('./nodes/297'),
	() => import('./nodes/298'),
	() => import('./nodes/299'),
	() => import('./nodes/300'),
	() => import('./nodes/301'),
	() => import('./nodes/302'),
	() => import('./nodes/303'),
	() => import('./nodes/304'),
	() => import('./nodes/305'),
	() => import('./nodes/306'),
	() => import('./nodes/307'),
	() => import('./nodes/308'),
	() => import('./nodes/309'),
	() => import('./nodes/310'),
	() => import('./nodes/311'),
	() => import('./nodes/312'),
	() => import('./nodes/313'),
	() => import('./nodes/314'),
	() => import('./nodes/315'),
	() => import('./nodes/316'),
	() => import('./nodes/317'),
	() => import('./nodes/318'),
	() => import('./nodes/319'),
	() => import('./nodes/320'),
	() => import('./nodes/321'),
	() => import('./nodes/322'),
	() => import('./nodes/323'),
	() => import('./nodes/324'),
	() => import('./nodes/325'),
	() => import('./nodes/326'),
	() => import('./nodes/327'),
	() => import('./nodes/328'),
	() => import('./nodes/329'),
	() => import('./nodes/330'),
	() => import('./nodes/331'),
	() => import('./nodes/332'),
	() => import('./nodes/333'),
	() => import('./nodes/334'),
	() => import('./nodes/335'),
	() => import('./nodes/336'),
	() => import('./nodes/337'),
	() => import('./nodes/338'),
	() => import('./nodes/339'),
	() => import('./nodes/340'),
	() => import('./nodes/341'),
	() => import('./nodes/342'),
	() => import('./nodes/343'),
	() => import('./nodes/344'),
	() => import('./nodes/345'),
	() => import('./nodes/346'),
	() => import('./nodes/347'),
	() => import('./nodes/348'),
	() => import('./nodes/349'),
	() => import('./nodes/350'),
	() => import('./nodes/351'),
	() => import('./nodes/352'),
	() => import('./nodes/353'),
	() => import('./nodes/354'),
	() => import('./nodes/355'),
	() => import('./nodes/356'),
	() => import('./nodes/357'),
	() => import('./nodes/358'),
	() => import('./nodes/359'),
	() => import('./nodes/360'),
	() => import('./nodes/361'),
	() => import('./nodes/362'),
	() => import('./nodes/363'),
	() => import('./nodes/364'),
	() => import('./nodes/365'),
	() => import('./nodes/366'),
	() => import('./nodes/367'),
	() => import('./nodes/368'),
	() => import('./nodes/369'),
	() => import('./nodes/370'),
	() => import('./nodes/371'),
	() => import('./nodes/372'),
	() => import('./nodes/373'),
	() => import('./nodes/374'),
	() => import('./nodes/375'),
	() => import('./nodes/376'),
	() => import('./nodes/377'),
	() => import('./nodes/378'),
	() => import('./nodes/379'),
	() => import('./nodes/380'),
	() => import('./nodes/381'),
	() => import('./nodes/382'),
	() => import('./nodes/383'),
	() => import('./nodes/384'),
	() => import('./nodes/385'),
	() => import('./nodes/386'),
	() => import('./nodes/387'),
	() => import('./nodes/388'),
	() => import('./nodes/389'),
	() => import('./nodes/390'),
	() => import('./nodes/391'),
	() => import('./nodes/392'),
	() => import('./nodes/393'),
	() => import('./nodes/394'),
	() => import('./nodes/395'),
	() => import('./nodes/396'),
	() => import('./nodes/397'),
	() => import('./nodes/398'),
	() => import('./nodes/399'),
	() => import('./nodes/400'),
	() => import('./nodes/401'),
	() => import('./nodes/402'),
	() => import('./nodes/403'),
	() => import('./nodes/404'),
	() => import('./nodes/405'),
	() => import('./nodes/406'),
	() => import('./nodes/407'),
	() => import('./nodes/408'),
	() => import('./nodes/409'),
	() => import('./nodes/410'),
	() => import('./nodes/411'),
	() => import('./nodes/412'),
	() => import('./nodes/413'),
	() => import('./nodes/414'),
	() => import('./nodes/415'),
	() => import('./nodes/416'),
	() => import('./nodes/417'),
	() => import('./nodes/418'),
	() => import('./nodes/419'),
	() => import('./nodes/420'),
	() => import('./nodes/421'),
	() => import('./nodes/422'),
	() => import('./nodes/423'),
	() => import('./nodes/424'),
	() => import('./nodes/425'),
	() => import('./nodes/426'),
	() => import('./nodes/427'),
	() => import('./nodes/428'),
	() => import('./nodes/429'),
	() => import('./nodes/430'),
	() => import('./nodes/431'),
	() => import('./nodes/432'),
	() => import('./nodes/433'),
	() => import('./nodes/434'),
	() => import('./nodes/435'),
	() => import('./nodes/436'),
	() => import('./nodes/437'),
	() => import('./nodes/438'),
	() => import('./nodes/439'),
	() => import('./nodes/440'),
	() => import('./nodes/441'),
	() => import('./nodes/442'),
	() => import('./nodes/443'),
	() => import('./nodes/444'),
	() => import('./nodes/445'),
	() => import('./nodes/446'),
	() => import('./nodes/447'),
	() => import('./nodes/448'),
	() => import('./nodes/449'),
	() => import('./nodes/450'),
	() => import('./nodes/451'),
	() => import('./nodes/452'),
	() => import('./nodes/453'),
	() => import('./nodes/454'),
	() => import('./nodes/455'),
	() => import('./nodes/456'),
	() => import('./nodes/457'),
	() => import('./nodes/458'),
	() => import('./nodes/459'),
	() => import('./nodes/460'),
	() => import('./nodes/461'),
	() => import('./nodes/462'),
	() => import('./nodes/463'),
	() => import('./nodes/464'),
	() => import('./nodes/465'),
	() => import('./nodes/466'),
	() => import('./nodes/467'),
	() => import('./nodes/468'),
	() => import('./nodes/469'),
	() => import('./nodes/470'),
	() => import('./nodes/471'),
	() => import('./nodes/472'),
	() => import('./nodes/473'),
	() => import('./nodes/474'),
	() => import('./nodes/475'),
	() => import('./nodes/476'),
	() => import('./nodes/477'),
	() => import('./nodes/478'),
	() => import('./nodes/479'),
	() => import('./nodes/480'),
	() => import('./nodes/481'),
	() => import('./nodes/482'),
	() => import('./nodes/483'),
	() => import('./nodes/484'),
	() => import('./nodes/485'),
	() => import('./nodes/486'),
	() => import('./nodes/487'),
	() => import('./nodes/488'),
	() => import('./nodes/489'),
	() => import('./nodes/490'),
	() => import('./nodes/491'),
	() => import('./nodes/492'),
	() => import('./nodes/493'),
	() => import('./nodes/494'),
	() => import('./nodes/495'),
	() => import('./nodes/496'),
	() => import('./nodes/497'),
	() => import('./nodes/498'),
	() => import('./nodes/499'),
	() => import('./nodes/500'),
	() => import('./nodes/501'),
	() => import('./nodes/502'),
	() => import('./nodes/503'),
	() => import('./nodes/504'),
	() => import('./nodes/505'),
	() => import('./nodes/506'),
	() => import('./nodes/507'),
	() => import('./nodes/508'),
	() => import('./nodes/509'),
	() => import('./nodes/510'),
	() => import('./nodes/511'),
	() => import('./nodes/512'),
	() => import('./nodes/513'),
	() => import('./nodes/514'),
	() => import('./nodes/515'),
	() => import('./nodes/516'),
	() => import('./nodes/517'),
	() => import('./nodes/518'),
	() => import('./nodes/519'),
	() => import('./nodes/520'),
	() => import('./nodes/521'),
	() => import('./nodes/522'),
	() => import('./nodes/523'),
	() => import('./nodes/524'),
	() => import('./nodes/525'),
	() => import('./nodes/526'),
	() => import('./nodes/527'),
	() => import('./nodes/528'),
	() => import('./nodes/529'),
	() => import('./nodes/530'),
	() => import('./nodes/531'),
	() => import('./nodes/532'),
	() => import('./nodes/533'),
	() => import('./nodes/534'),
	() => import('./nodes/535'),
	() => import('./nodes/536'),
	() => import('./nodes/537'),
	() => import('./nodes/538'),
	() => import('./nodes/539'),
	() => import('./nodes/540'),
	() => import('./nodes/541'),
	() => import('./nodes/542'),
	() => import('./nodes/543'),
	() => import('./nodes/544'),
	() => import('./nodes/545'),
	() => import('./nodes/546'),
	() => import('./nodes/547'),
	() => import('./nodes/548'),
	() => import('./nodes/549'),
	() => import('./nodes/550'),
	() => import('./nodes/551'),
	() => import('./nodes/552'),
	() => import('./nodes/553'),
	() => import('./nodes/554'),
	() => import('./nodes/555'),
	() => import('./nodes/556'),
	() => import('./nodes/557'),
	() => import('./nodes/558'),
	() => import('./nodes/559'),
	() => import('./nodes/560'),
	() => import('./nodes/561'),
	() => import('./nodes/562'),
	() => import('./nodes/563'),
	() => import('./nodes/564'),
	() => import('./nodes/565'),
	() => import('./nodes/566'),
	() => import('./nodes/567'),
	() => import('./nodes/568'),
	() => import('./nodes/569'),
	() => import('./nodes/570'),
	() => import('./nodes/571'),
	() => import('./nodes/572'),
	() => import('./nodes/573'),
	() => import('./nodes/574'),
	() => import('./nodes/575'),
	() => import('./nodes/576'),
	() => import('./nodes/577'),
	() => import('./nodes/578'),
	() => import('./nodes/579'),
	() => import('./nodes/580'),
	() => import('./nodes/581'),
	() => import('./nodes/582'),
	() => import('./nodes/583')
];

export const server_loads = [0];

export const dictionary = {
		"/": [42],
		"/assets": [43],
		"/blog/author": [45,[2]],
		"/blog/author/aditya-oberai": [46,[2]],
		"/blog/author/arman": [47,[2]],
		"/blog/author/binyamin-yawitz": [48,[2]],
		"/blog/author/bradley-schofield": [49,[2]],
		"/blog/author/caio-arias": [50,[2]],
		"/blog/author/christy-jacob": [51,[2]],
		"/blog/author/damodar-lohani": [52,[2]],
		"/blog/author/dennis-ivy": [53,[2]],
		"/blog/author/ebenezer-don": [54,[2]],
		"/blog/author/eldad-fux": [55,[2]],
		"/blog/author/haimantika-mitra": [56,[2]],
		"/blog/author/jade-baudchon": [57,[2]],
		"/blog/author/jake-barnby": [58,[2]],
		"/blog/author/jesse-winton": [59,[2]],
		"/blog/author/laura-du-ry": [60,[2]],
		"/blog/author/luke-silver": [61,[2]],
		"/blog/author/matej-baco": [62,[2]],
		"/blog/author/may-ender": [63,[2]],
		"/blog/author/sara-kaandorp": [64,[2]],
		"/blog/author/snezhanna": [65,[2]],
		"/blog/author/thomas-g-lopes": [66,[2]],
		"/blog/author/vincent-ge": [67,[2]],
		"/blog/category": [68,[2]],
		"/blog/category/GDPR": [69,[2]],
		"/blog/category/accessibility": [70,[2]],
		"/blog/category/case-studies": [71,[2]],
		"/blog/category/company": [72,[2]],
		"/blog/category/contributors": [73,[2]],
		"/blog/category/culture": [74,[2]],
		"/blog/category/design": [75,[2]],
		"/blog/category/devrel": [76,[2]],
		"/blog/category/hacktoberfest": [77,[2]],
		"/blog/category/init": [78,[2]],
		"/blog/category/integrations": [79,[2]],
		"/blog/category/open-source": [80,[2]],
		"/blog/category/product": [81,[2]],
		"/blog/category/security": [82,[2]],
		"/blog/category/tutorial": [83,[2]],
		"/blog/post": [84,[2]],
		"/blog/post/3-things-you-can-build-with-go-runtime": [85,[2]],
		"/blog/post/7-steps-to-achieve-gdpr-compliance-for-startups": [86,[2]],
		"/blog/post/How-to-put-privacy-first": [87,[2]],
		"/blog/post/a-recap-of-init": [88,[2]],
		"/blog/post/accessibility-in-pink-design": [89,[2]],
		"/blog/post/add-a-search-function-to-your-app": [90,[2]],
		"/blog/post/adding-url-shortener-function": [91,[2]],
		"/blog/post/ai-crystal-ball": [92,[2]],
		"/blog/post/announcing-appwrite-daily-dot-dev-squad": [93,[2]],
		"/blog/post/announcing-appwrite-integration-catalog": [94,[2]],
		"/blog/post/announcing-appwrite-is-gdpr-compliant": [95,[2]],
		"/blog/post/announcing-appwrite-is-hipaa-compliant": [96,[2]],
		"/blog/post/announcing-appwrite-messaging": [97,[2]],
		"/blog/post/announcing-appwrite-new-ai-integrations": [98,[2]],
		"/blog/post/announcing-appwrite-pro": [99,[2]],
		"/blog/post/announcing-appwrite-startups-program": [100,[2]],
		"/blog/post/announcing-go-support": [101,[2]],
		"/blog/post/announcing-init-faster-smoother-better": [103,[2]],
		"/blog/post/announcing-init": [102,[2]],
		"/blog/post/announcing-local-development": [104,[2]],
		"/blog/post/announcing-mock-numbers-session-alerts": [105,[2]],
		"/blog/post/announcing-more-and-updated-runtimes": [106,[2]],
		"/blog/post/announcing-new-changelog": [107,[2]],
		"/blog/post/announcing-pricing": [108,[2]],
		"/blog/post/announcing-the-appwrite-oss-program": [109,[2]],
		"/blog/post/announcing-two-factor-authentication": [110,[2]],
		"/blog/post/appwrite-1.5-now-available-on-cloud": [111,[2]],
		"/blog/post/appwrite-compared-to-supabase": [112,[2]],
		"/blog/post/appwrite-decoded-bradley-schofield": [113,[2]],
		"/blog/post/appwrite-decoded-dennis-ivy": [114,[2]],
		"/blog/post/appwrite-decoded-dylan": [115,[2]],
		"/blog/post/appwrite-decoded-khushboo-verma": [116,[2]],
		"/blog/post/appwrite-decoded-sara-kaandorp": [117,[2]],
		"/blog/post/appwrite-is-now-soc-2-type-1-compliant": [118,[2]],
		"/blog/post/appwrite-messaging-is-free-for-six-months": [119,[2]],
		"/blog/post/appwrite-realtime-for-flutter": [120,[2]],
		"/blog/post/appwrite-vs-firebase-vs-supabase-functions-comparison": [121,[2]],
		"/blog/post/baas-vs-custom-backend": [122,[2]],
		"/blog/post/behind-the-pr-tales-from-the-open-source-world": [123,[2]],
		"/blog/post/best-pagination-technique": [124,[2]],
		"/blog/post/building-apps-with-bun-and-appwrite": [125,[2]],
		"/blog/post/building-cross-platform-applications-with-react-native": [126,[2]],
		"/blog/post/building-init-giveaway-app": [127,[2]],
		"/blog/post/building-with-ai-function-templates": [128,[2]],
		"/blog/post/bun-function-resume": [129,[2]],
		"/blog/post/case-study-kcollect": [130,[2]],
		"/blog/post/case-study-langx": [131,[2]],
		"/blog/post/case-study-majik-kids": [132,[2]],
		"/blog/post/case-study-myshoefitter": [133,[2]],
		"/blog/post/case-study-open-mind": [134,[2]],
		"/blog/post/case-study-smartbee": [135,[2]],
		"/blog/post/case-study-undo": [136,[2]],
		"/blog/post/celebrating-1.5-contributors": [137,[2]],
		"/blog/post/ci-cd-examples-in-appwrite": [138,[2]],
		"/blog/post/cors-error": [139,[2]],
		"/blog/post/csr-vs-ssr-with-nextjs": [140,[2]],
		"/blog/post/defying-the-laws-of-web-animations": [141,[2]],
		"/blog/post/deploy-a-pdf-generation-service-with-appwrite-functions": [142,[2]],
		"/blog/post/designing-init-event-logo": [143,[2]],
		"/blog/post/designing-the-new-appwrite-website": [144,[2]],
		"/blog/post/developer-tools-appwrite": [145,[2]],
		"/blog/post/dont-blame-the-readers-write-the-docs-they-need": [146,[2]],
		"/blog/post/email-otp-auth-sveltekit": [147,[2]],
		"/blog/post/enhancing-type-safety": [148,[2]],
		"/blog/post/ensuring-security-amidst-xz-concern": [149,[2]],
		"/blog/post/enums-api-design": [150,[2]],
		"/blog/post/everything-new-with-appwrite-1.5": [151,[2]],
		"/blog/post/function-chat-fictional-character": [152,[2]],
		"/blog/post/function-template-prompt-chatgpt": [153,[2]],
		"/blog/post/function-template-whatsapp-vonage": [154,[2]],
		"/blog/post/functions-local-development-guide": [155,[2]],
		"/blog/post/gdpr-compliance-mobile-apps-alternative-firebase": [156,[2]],
		"/blog/post/goodbye-plaintext-passwords": [157,[2]],
		"/blog/post/hacktoberfest-2023": [158,[2]],
		"/blog/post/handle-cors-in-serverless-functions": [159,[2]],
		"/blog/post/hf-2023-journey": [160,[2]],
		"/blog/post/how-to-attract-users-to-open-source-project": [161,[2]],
		"/blog/post/how-to-back-up-your-appwrite-data": [162,[2]],
		"/blog/post/how-to-build-a-remote-tech-stack": [163,[2]],
		"/blog/post/how-to-build-your-digital-event-tickets": [164,[2]],
		"/blog/post/how-to-execute-database-migration-with-appwrite-cli": [165,[2]],
		"/blog/post/how-to-leverage-dynamic-api-keys-for-better-security": [166,[2]],
		"/blog/post/image-classification": [167,[2]],
		"/blog/post/improve-ux-passwordless-auth": [168,[2]],
		"/blog/post/incident-report-feb-24": [169,[2]],
		"/blog/post/integrate-custom-auth-sveltekit": [170,[2]],
		"/blog/post/integrate-sql-nosql-vector-graph-or-any-database-into-your-appwrite-project": [171,[2]],
		"/blog/post/introducing-appwrite-react-native-sdk": [172,[2]],
		"/blog/post/introducing-enum-sdk-support": [173,[2]],
		"/blog/post/introducing-functions-ecosystem": [174,[2]],
		"/blog/post/introducing-new-appwrite-cli": [175,[2]],
		"/blog/post/introducing-new-database-operators": [176,[2]],
		"/blog/post/introducing-python-machine-learning-runtime": [177,[2]],
		"/blog/post/introducing-support-for-server-side-rendering": [178,[2]],
		"/blog/post/its-the-contributors-in-open-source-who-make-the-community-great": [179,[2]],
		"/blog/post/leveraging-baas-tools-to-scale-faster": [180,[2]],
		"/blog/post/make-open-source-healthier": [181,[2]],
		"/blog/post/manage-user-permissions-with-labels-and-teams": [182,[2]],
		"/blog/post/meet-the-new-appwrite": [183,[2]],
		"/blog/post/messaging-explained": [184,[2]],
		"/blog/post/migrate-firebase-projects-to-appwrite": [185,[2]],
		"/blog/post/mock-numbers-best-practices": [186,[2]],
		"/blog/post/oauth-openid": [187,[2]],
		"/blog/post/open-source-contributors-16": [188,[2]],
		"/blog/post/open-source-firebase-alternative": [189,[2]],
		"/blog/post/oss-journey-blog": [190,[2]],
		"/blog/post/password-hashing-algorithms": [191,[2]],
		"/blog/post/password-protection-2fa": [193,[2]],
		"/blog/post/password-protection": [192,[2]],
		"/blog/post/personal-chatbot-gpt-4o": [194,[2]],
		"/blog/post/planetscale-databases-alternative": [195,[2]],
		"/blog/post/preventing-password-sharing": [196,[2]],
		"/blog/post/product-update-august": [197,[2]],
		"/blog/post/product-update-june": [198,[2]],
		"/blog/post/public-beta": [199,[2]],
		"/blog/post/public-roadmap-announcement": [200,[2]],
		"/blog/post/push-notifications-best-practices": [201,[2]],
		"/blog/post/rest-vs-graphql-websockets-which-is-best-for-your-app": [202,[2]],
		"/blog/post/scan-receipts-with-appwrite-functions": [203,[2]],
		"/blog/post/serverless-functions-best-practices": [204,[2]],
		"/blog/post/set-up-google-auth-appwrite-react": [205,[2]],
		"/blog/post/simplify-messaging-twilio": [206,[2]],
		"/blog/post/simplify-your-data-management-with-relationships": [207,[2]],
		"/blog/post/social-media-auth": [208,[2]],
		"/blog/post/sound-null-safety-for-your-dart-functions": [209,[2]],
		"/blog/post/state-of-audio-processing": [210,[2]],
		"/blog/post/state-of-computer-vision": [211,[2]],
		"/blog/post/state-of-natural-language-processing": [212,[2]],
		"/blog/post/swift-101-build-a-library-with-swift-package-manager": [213,[2]],
		"/blog/post/the-evolution-of-team-appwrite": [214,[2]],
		"/blog/post/the-journey-and-meaning-behind-our-new-logo": [215,[2]],
		"/blog/post/the-subtle-art-of-hackathon ideation": [216,[2]],
		"/blog/post/three-important-steps-you-need-to-complete-with-appwrite": [217,[2]],
		"/blog/post/understand-data-queries": [218,[2]],
		"/blog/post/valentines-day-sonnet-generator": [219,[2]],
		"/blog/post/what-is-golang": [220,[2]],
		"/blog/post/what-is-hipaa-compliant": [221,[2]],
		"/blog/post/why-you-need-to-try-the-new-bun-runtime": [222,[2]],
		"/blog/[[page]]": [44,[2]],
		"/brand": [~223],
		"/case-studies": [~224],
		"/changelog/entry/[entry]": [226],
		"/changelog/[[page]]": [225],
		"/community": [~227],
		"/company": [228],
		"/company/careers": [229],
		"/contact-us": [230],
		"/contact-us/enterprise": [231],
		"/cookies": [232],
		"/discord": [~233],
		"/docs": [234,[],[3]],
		"/docs/advanced/integration": [235,[,4],[3]],
		"/docs/advanced/migrations": [236,[,5],[3]],
		"/docs/advanced/migrations/cloud": [237,[,5],[3]],
		"/docs/advanced/migrations/firebase": [238,[,5],[3]],
		"/docs/advanced/migrations/nhost": [239,[,5],[3]],
		"/docs/advanced/migrations/self-hosted": [240,[,5],[3]],
		"/docs/advanced/migrations/supabase": [241,[,5],[3]],
		"/docs/advanced/platform": [242,[,6],[3]],
		"/docs/advanced/platform/api-keys": [243,[,6],[3]],
		"/docs/advanced/platform/billing": [244,[,6],[3]],
		"/docs/advanced/platform/custom-domains": [245,[,6],[3]],
		"/docs/advanced/platform/enterprise": [246,[,6],[3]],
		"/docs/advanced/platform/events": [247,[,6],[3]],
		"/docs/advanced/platform/free": [248,[,6],[3]],
		"/docs/advanced/platform/message-templates": [249,[,6],[3]],
		"/docs/advanced/platform/oss": [250,[,6],[3]],
		"/docs/advanced/platform/permissions": [251,[,6],[3]],
		"/docs/advanced/platform/pro": [252,[,6],[3]],
		"/docs/advanced/platform/rate-limits": [253,[,6],[3]],
		"/docs/advanced/platform/release-policy": [254,[,6],[3]],
		"/docs/advanced/platform/response-codes": [255,[,6],[3]],
		"/docs/advanced/platform/scale": [256,[,6],[3]],
		"/docs/advanced/platform/shortcuts": [257,[,6],[3]],
		"/docs/advanced/platform/webhooks": [258,[,6],[3]],
		"/docs/advanced/security": [259,[,7],[3]],
		"/docs/advanced/security/abuse-protection": [260,[,7],[3]],
		"/docs/advanced/security/audit-logs": [261,[,7],[3]],
		"/docs/advanced/security/authentication": [262,[,7],[3]],
		"/docs/advanced/security/backups": [263,[,7],[3]],
		"/docs/advanced/security/encryption": [264,[,7],[3]],
		"/docs/advanced/security/gdpr": [265,[,7],[3]],
		"/docs/advanced/security/hipaa": [266,[,7],[3]],
		"/docs/advanced/security/https": [267,[,7],[3]],
		"/docs/advanced/security/mfa": [268,[,7],[3]],
		"/docs/advanced/security/pci": [269,[,7],[3]],
		"/docs/advanced/security/penetration-tests": [270,[,7],[3]],
		"/docs/advanced/security/soc2": [271,[,7],[3]],
		"/docs/advanced/security/tls": [272,[,7],[3]],
		"/docs/advanced/self-hosting": [273,[,8],[3]],
		"/docs/advanced/self-hosting/debug": [274,[,8],[3]],
		"/docs/advanced/self-hosting/email": [275,[,8],[3]],
		"/docs/advanced/self-hosting/environment-variables": [276,[,8],[3]],
		"/docs/advanced/self-hosting/functions": [277,[,8],[3]],
		"/docs/advanced/self-hosting/production": [278,[,8],[3]],
		"/docs/advanced/self-hosting/sms": [279,[,8],[3]],
		"/docs/advanced/self-hosting/storage": [280,[,8],[3]],
		"/docs/advanced/self-hosting/tls-certificates": [281,[,8],[3]],
		"/docs/advanced/self-hosting/update": [282,[,8],[3]],
		"/docs/apis/graphql": [283,[,9],[3]],
		"/docs/apis/realtime": [284,[,9],[3]],
		"/docs/apis/rest": [285,[,9],[3]],
		"/docs/products/ai": [286,[,10],[3]],
		"/docs/products/ai/audio-processing": [287,[,10],[3]],
		"/docs/products/ai/computer-vision": [288,[,10],[3]],
		"/docs/products/ai/integrations/anyscale": [289,[,10],[3]],
		"/docs/products/ai/integrations/elevenlabs": [290,[,10],[3]],
		"/docs/products/ai/integrations/fal-ai": [291,[,10],[3]],
		"/docs/products/ai/integrations/langchain": [292,[,10],[3]],
		"/docs/products/ai/integrations/lmnt": [293,[,10],[3]],
		"/docs/products/ai/integrations/openai": [294,[,10],[3]],
		"/docs/products/ai/integrations/perplexity": [295,[,10],[3]],
		"/docs/products/ai/integrations/pinecone": [296,[,10],[3]],
		"/docs/products/ai/integrations/replicate": [297,[,10],[3]],
		"/docs/products/ai/integrations/tensorflow": [298,[,10],[3]],
		"/docs/products/ai/integrations/togetherai": [299,[,10],[3]],
		"/docs/products/ai/natural-language": [300,[,10],[3]],
		"/docs/products/ai/tutorials/image-classification": [301,[,10],[3]],
		"/docs/products/ai/tutorials/language-translation": [302,[,10],[3]],
		"/docs/products/ai/tutorials/music-generation": [303,[,10],[3]],
		"/docs/products/ai/tutorials/object-detection": [304,[,10],[3]],
		"/docs/products/ai/tutorials/speech-recognition": [305,[,10],[3]],
		"/docs/products/ai/tutorials/text-generation": [306,[,10],[3]],
		"/docs/products/ai/tutorials/text-to-speech": [307,[,10],[3]],
		"/docs/products/ai/video-processing": [308,[,10],[3]],
		"/docs/products/auth": [309,[,11],[3]],
		"/docs/products/auth/accounts": [310,[,11],[3]],
		"/docs/products/auth/anonymous": [311,[,11],[3]],
		"/docs/products/auth/custom-token": [312,[,11],[3]],
		"/docs/products/auth/email-otp": [313,[,11],[3]],
		"/docs/products/auth/email-password": [314,[,11],[3]],
		"/docs/products/auth/jwt": [315,[,11],[3]],
		"/docs/products/auth/labels": [316,[,11],[3]],
		"/docs/products/auth/magic-url": [317,[,11],[3]],
		"/docs/products/auth/mfa": [318,[,11],[3]],
		"/docs/products/auth/oauth2": [319,[,11],[3]],
		"/docs/products/auth/phone-sms": [320,[,11],[3]],
		"/docs/products/auth/quick-start": [321,[,11],[3]],
		"/docs/products/auth/security": [322,[,11],[3]],
		"/docs/products/auth/server-side-rendering": [323,[,11],[3]],
		"/docs/products/auth/teams": [324,[,11],[3]],
		"/docs/products/auth/tokens": [325,[,11],[3]],
		"/docs/products/auth/users": [326,[,11],[3]],
		"/docs/products/databases": [327,[,12],[3]],
		"/docs/products/databases/collections": [328,[,12],[3]],
		"/docs/products/databases/databases": [329,[,12],[3]],
		"/docs/products/databases/documents": [330,[,12],[3]],
		"/docs/products/databases/order": [331,[,12],[3]],
		"/docs/products/databases/pagination": [332,[,12],[3]],
		"/docs/products/databases/permissions": [333,[,12],[3]],
		"/docs/products/databases/queries": [334,[,12],[3]],
		"/docs/products/databases/quick-start": [335,[,12],[3]],
		"/docs/products/databases/relationships": [336,[,12],[3]],
		"/docs/products/functions": [337,[,13],[3]],
		"/docs/products/functions/deploy-from-git": [338,[,13],[3]],
		"/docs/products/functions/deploy-manually": [339,[,13],[3]],
		"/docs/products/functions/deployments": [340,[,13],[3]],
		"/docs/products/functions/develop-locally": [342,[,13],[3]],
		"/docs/products/functions/develop": [341,[,13],[3]],
		"/docs/products/functions/domains": [343,[,13],[3]],
		"/docs/products/functions/examples": [344,[,13],[3]],
		"/docs/products/functions/execute": [345,[,13],[3]],
		"/docs/products/functions/executions": [346,[,13],[3]],
		"/docs/products/functions/functions": [347,[,13],[3]],
		"/docs/products/functions/quick-start": [348,[,13],[3]],
		"/docs/products/functions/runtimes": [349,[,13],[3]],
		"/docs/products/functions/templates": [350,[,13],[3]],
		"/docs/products/messaging": [351,[,14],[3]],
		"/docs/products/messaging/apns": [352,[,14],[3]],
		"/docs/products/messaging/fcm": [353,[,14],[3]],
		"/docs/products/messaging/mailgun": [354,[,14],[3]],
		"/docs/products/messaging/messages": [355,[,14],[3]],
		"/docs/products/messaging/msg91": [356,[,14],[3]],
		"/docs/products/messaging/send-email-messages": [357,[,14],[3]],
		"/docs/products/messaging/send-push-notifications": [358,[,14],[3]],
		"/docs/products/messaging/send-sms-messages": [359,[,14],[3]],
		"/docs/products/messaging/sendgrid": [360,[,14],[3]],
		"/docs/products/messaging/smtp": [361,[,14],[3]],
		"/docs/products/messaging/targets": [362,[,14],[3]],
		"/docs/products/messaging/telesign": [363,[,14],[3]],
		"/docs/products/messaging/textmagic": [364,[,14],[3]],
		"/docs/products/messaging/topics": [365,[,14],[3]],
		"/docs/products/messaging/twilio": [366,[,14],[3]],
		"/docs/products/messaging/vonage": [367,[,14],[3]],
		"/docs/products/storage": [368,[,15],[3]],
		"/docs/products/storage/buckets": [369,[,15],[3]],
		"/docs/products/storage/images": [370,[,15],[3]],
		"/docs/products/storage/permissions": [371,[,15],[3]],
		"/docs/products/storage/quick-start": [372,[,15],[3]],
		"/docs/products/storage/upload-download": [373,[,15],[3]],
		"/docs/quick-starts": [374,[,16],[3]],
		"/docs/quick-starts/android": [375,[,16],[3]],
		"/docs/quick-starts/angular": [376,[,16],[3]],
		"/docs/quick-starts/apple": [377,[,16],[3]],
		"/docs/quick-starts/astro": [378,[,16],[3]],
		"/docs/quick-starts/dart": [379,[,16],[3]],
		"/docs/quick-starts/deno": [380,[,16],[3]],
		"/docs/quick-starts/dotnet": [381,[,16],[3]],
		"/docs/quick-starts/flutter": [382,[,16],[3]],
		"/docs/quick-starts/go": [383,[,16],[3]],
		"/docs/quick-starts/kotlin": [384,[,16],[3]],
		"/docs/quick-starts/nextjs": [385,[,16],[3]],
		"/docs/quick-starts/node": [386,[,16],[3]],
		"/docs/quick-starts/nuxt": [387,[,16],[3]],
		"/docs/quick-starts/php": [388,[,16],[3]],
		"/docs/quick-starts/python": [389,[,16],[3]],
		"/docs/quick-starts/qwik": [390,[,16],[3]],
		"/docs/quick-starts/react-native": [392,[,16],[3]],
		"/docs/quick-starts/react": [391,[,16],[3]],
		"/docs/quick-starts/refine": [393,[,16],[3]],
		"/docs/quick-starts/ruby": [394,[,16],[3]],
		"/docs/quick-starts/solid": [395,[,16],[3]],
		"/docs/quick-starts/sveltekit": [396,[,16],[3]],
		"/docs/quick-starts/swift": [397,[,16],[3]],
		"/docs/quick-starts/vue": [398,[,16],[3]],
		"/docs/quick-starts/web": [399,[,16],[3]],
		"/docs/references": [400,[,17],[3]],
		"/docs/references/quick-start": [403,[,17],[3]],
		"/docs/references/[version]/models/[model]": [~402,[,17],[3]],
		"/docs/references/[version]/[platform]/[service]": [~401,[,17],[3]],
		"/docs/sdks": [404,[,18],[3]],
		"/docs/tooling/appwriter": [405,[,19],[3]],
		"/docs/tooling/assistant": [406,[,20],[3]],
		"/docs/tooling/command-center": [407,[,21],[3]],
		"/docs/tooling/command-line/buckets": [408,[,22],[3]],
		"/docs/tooling/command-line/collections": [409,[,22],[3]],
		"/docs/tooling/command-line/commands": [410,[,22],[3]],
		"/docs/tooling/command-line/functions": [411,[,22],[3]],
		"/docs/tooling/command-line/installation": [412,[,22],[3]],
		"/docs/tooling/command-line/non-interactive": [413,[,22],[3]],
		"/docs/tooling/command-line/teams": [414,[,22],[3]],
		"/docs/tooling/command-line/topics": [415,[,22],[3]],
		"/docs/tutorials": [416,[,23],[3]],
		"/docs/tutorials/android": [417,[,23,24],[3]],
		"/docs/tutorials/android/step-1": [418,[,23,24],[3]],
		"/docs/tutorials/android/step-2": [419,[,23,24],[3]],
		"/docs/tutorials/android/step-3": [420,[,23,24],[3]],
		"/docs/tutorials/android/step-4": [421,[,23,24],[3]],
		"/docs/tutorials/android/step-5": [422,[,23,24],[3]],
		"/docs/tutorials/android/step-6": [423,[,23,24],[3]],
		"/docs/tutorials/android/step-7": [424,[,23,24],[3]],
		"/docs/tutorials/android/step-8": [425,[,23,24],[3]],
		"/docs/tutorials/apple": [426,[,23,25],[3]],
		"/docs/tutorials/apple/step-1": [427,[,23,25],[3]],
		"/docs/tutorials/astro-ssr-auth": [428,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-1": [429,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-2": [430,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-3": [431,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-4": [432,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-5": [433,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-6": [434,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-7": [435,[,23,26],[3]],
		"/docs/tutorials/astro-ssr-auth/step-8": [436,[,23,26],[3]],
		"/docs/tutorials/flutter": [437,[,23,27],[3]],
		"/docs/tutorials/flutter/step-1": [438,[,23,27],[3]],
		"/docs/tutorials/nextjs-ssr-auth": [439,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-1": [440,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-2": [441,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-3": [442,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-4": [443,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-5": [444,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-6": [445,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-7": [446,[,23,28],[3]],
		"/docs/tutorials/nextjs-ssr-auth/step-8": [447,[,23,28],[3]],
		"/docs/tutorials/nuxt-ssr-auth": [457,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-1": [458,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-2": [459,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-3": [460,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-4": [461,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-5": [462,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-6": [463,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-7": [464,[,23,30],[3]],
		"/docs/tutorials/nuxt-ssr-auth/step-8": [465,[,23,30],[3]],
		"/docs/tutorials/nuxt": [448,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-1": [449,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-2": [450,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-3": [451,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-4": [452,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-5": [453,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-6": [454,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-7": [455,[,23,29],[3]],
		"/docs/tutorials/nuxt/step-8": [456,[,23,29],[3]],
		"/docs/tutorials/react-native": [475,[,23,32],[3]],
		"/docs/tutorials/react-native/step-1": [476,[,23,32],[3]],
		"/docs/tutorials/react-native/step-2": [477,[,23,32],[3]],
		"/docs/tutorials/react-native/step-3": [478,[,23,32],[3]],
		"/docs/tutorials/react-native/step-4": [479,[,23,32],[3]],
		"/docs/tutorials/react-native/step-5": [480,[,23,32],[3]],
		"/docs/tutorials/react-native/step-6": [481,[,23,32],[3]],
		"/docs/tutorials/react-native/step-7": [482,[,23,32],[3]],
		"/docs/tutorials/react-native/step-8": [483,[,23,32],[3]],
		"/docs/tutorials/react": [466,[,23,31],[3]],
		"/docs/tutorials/react/step-1": [467,[,23,31],[3]],
		"/docs/tutorials/react/step-2": [468,[,23,31],[3]],
		"/docs/tutorials/react/step-3": [469,[,23,31],[3]],
		"/docs/tutorials/react/step-4": [470,[,23,31],[3]],
		"/docs/tutorials/react/step-5": [471,[,23,31],[3]],
		"/docs/tutorials/react/step-6": [472,[,23,31],[3]],
		"/docs/tutorials/react/step-7": [473,[,23,31],[3]],
		"/docs/tutorials/react/step-8": [474,[,23,31],[3]],
		"/docs/tutorials/refine": [484,[,23,33],[3]],
		"/docs/tutorials/refine/step-1": [485,[,23,33],[3]],
		"/docs/tutorials/refine/step-2": [486,[,23,33],[3]],
		"/docs/tutorials/refine/step-3": [487,[,23,33],[3]],
		"/docs/tutorials/refine/step-4": [488,[,23,33],[3]],
		"/docs/tutorials/refine/step-5": [489,[,23,33],[3]],
		"/docs/tutorials/refine/step-6": [490,[,23,33],[3]],
		"/docs/tutorials/refine/step-7": [491,[,23,33],[3]],
		"/docs/tutorials/subscriptions-with-stripe": [492,[,23,34],[3]],
		"/docs/tutorials/subscriptions-with-stripe/step-1": [493,[,23,34],[3]],
		"/docs/tutorials/subscriptions-with-stripe/step-2": [494,[,23,34],[3]],
		"/docs/tutorials/subscriptions-with-stripe/step-3": [495,[,23,34],[3]],
		"/docs/tutorials/subscriptions-with-stripe/step-4": [496,[,23,34],[3]],
		"/docs/tutorials/subscriptions-with-stripe/step-5": [497,[,23,34],[3]],
		"/docs/tutorials/sveltekit-csr-auth": [506,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-1": [507,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-2": [508,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-3": [509,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-4": [510,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-5": [511,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-6": [512,[,23,36],[3]],
		"/docs/tutorials/sveltekit-csr-auth/step-7": [513,[,23,36],[3]],
		"/docs/tutorials/sveltekit-ssr-auth": [514,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-1": [515,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-2": [516,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-3": [517,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-4": [518,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-5": [519,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-6": [520,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-7": [521,[,23,37],[3]],
		"/docs/tutorials/sveltekit-ssr-auth/step-8": [522,[,23,37],[3]],
		"/docs/tutorials/sveltekit": [498,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-1": [499,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-2": [500,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-3": [501,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-4": [502,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-5": [503,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-6": [504,[,23,35],[3]],
		"/docs/tutorials/sveltekit/step-7": [505,[,23,35],[3]],
		"/docs/tutorials/vue": [523,[,23,38],[3]],
		"/docs/tutorials/vue/step-1": [524,[,23,38],[3]],
		"/docs/tutorials/vue/step-2": [525,[,23,38],[3]],
		"/docs/tutorials/vue/step-3": [526,[,23,38],[3]],
		"/docs/tutorials/vue/step-4": [527,[,23,38],[3]],
		"/docs/tutorials/vue/step-5": [528,[,23,38],[3]],
		"/docs/tutorials/vue/step-6": [529,[,23,38],[3]],
		"/docs/tutorials/vue/step-7": [530,[,23,38],[3]],
		"/docs/tutorials/vue/step-8": [531,[,23,38],[3]],
		"/hacktoberfest": [~532],
		"/heroes": [533],
		"/init-0": [538],
		"/init-0/tickets": [539,[40]],
		"/init-0/tickets/customize": [541,[40]],
		"/init-0/tickets/[id]": [540,[40]],
		"/init": [534],
		"/init/tickets": [535,[39]],
		"/init/tickets/customize": [537,[39]],
		"/init/tickets/[id]": [536,[39]],
		"/integrations": [542],
		"/integrations/ai-hugging-face-image-classification": [543],
		"/integrations/ai-hugging-face-language-translation": [544],
		"/integrations/ai-hugging-face-speech-recognition": [545],
		"/integrations/ai-openai": [546],
		"/integrations/ai-perplexity": [547],
		"/integrations/deployments-github": [548],
		"/integrations/email-sendgrid": [549],
		"/integrations/lemon-squeezy-payments": [550],
		"/integrations/lemon-squeezy-subscriptions": [551],
		"/integrations/logging-appsignal": [552],
		"/integrations/logging-raygun": [553],
		"/integrations/logging-sentry": [554],
		"/integrations/oauth-amazon": [555],
		"/integrations/oauth-apple": [556],
		"/integrations/oauth-discord": [557],
		"/integrations/oauth-google": [558],
		"/integrations/oauth-notion": [559],
		"/integrations/phone-auth-twilio": [560],
		"/integrations/push-apns": [561],
		"/integrations/push-fcm": [562],
		"/integrations/query-mongodb": [563],
		"/integrations/query-upstash": [564],
		"/integrations/search-algolia": [565],
		"/integrations/sms-twilio": [566],
		"/integrations/storage-s3": [567],
		"/integrations/stripe-payments": [568],
		"/integrations/stripe-subscriptions": [569],
		"/integrations/technology-partner": [570],
		"/integrations/whatsapp-vonage": [571],
		"/oss-program": [572],
		"/pricing": [573],
		"/privacy": [574],
		"/products/messaging": [575],
		"/startups": [576],
		"/startups/careers": [577],
		"/subscription": [578],
		"/subscription/verify": [579],
		"/support": [~580],
		"/terms": [581],
		"/threads": [582,[41]],
		"/threads/[id]": [~583,[41]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';